import React, { ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { cta } from "../../styles/typography";

const button = css`
  ${cta};
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(6)} ${pxToRem(32)};
  border-radius: ${variables.roundness2};
  transition:
    background-color ${variables.transitionSpeed} ease,
    color ${variables.transitionSpeed} ease;
  color: ${variables.colorPrimaryGray};

  &:hover {
    color: ${variables.colorText};
  }

  &:focus,
  &:active {
    opacity: ${variables.disabledOpacity};
  }

  &:disabled {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
  }
`;

const layout = css`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto;
`;

type Input = {
  style?: SerializedStyles;
  children: ReactNode;
  type?: "button" | "submit";
  disabled?: boolean;
  dataTest?: string;
  onClick?: (e) => void;
};

const CancelButton = ({
  style,
  children,
  onClick,
  type = "button",
  dataTest,
  disabled = false,
}: Input): ReactElement => {
  return (
    <button
      css={[button, style]}
      type={type}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      <span css={layout}>{children}</span>
    </button>
  );
};

export default CancelButton;
