const limitWordsByCharacterLength = (
  string: string,
  maxLength: number
): string => {
  if (!string) {
    return null;
  }

  if (string.length < maxLength) {
    return string;
  }

  const trimmedString = string.slice(0, maxLength - 3);
  const trimmedWordsArr = trimmedString.split(" ");

  if (trimmedWordsArr.length > 1) {
    const trimmedWordsString = trimmedWordsArr
      .slice(0, trimmedWordsArr.length - 1)
      .join(" ");
    return `${trimmedWordsString}…`;
  }

  return trimmedString;
};

export default limitWordsByCharacterLength;
