import React, { ReactElement } from "react";
import { css } from "@emotion/react";
import { decode } from "html-entities";
import { SerializedStyles } from "@emotion/react/types";

import CloseButton from "../common/close-button";
import { conversation, paragraph, h6Regular } from "../../styles/typography";
import pxToRem from "../../styles/px-to-rem";
import variables from "../../styles/variables";
import limitWordsByCharacterLength from "../../helpers/limit-words-by-character-length";

const wrapper = css`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  border-bottom: ${pxToRem(1)} solid ${variables.colorSeparator};
  padding: ${variables.spaceSmall} ${variables.spaceBase}
    ${variables.spaceSmall} ${variables.spaceMedium};
`;

const titleSection = css`
  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: end;
    grid-gap: ${variables.spaceTiny};
  }
`;

const modalTitle = css`
  ${conversation};
`;

const modalSubTitle = css`
  ${paragraph};
`;

const headerLabelStyle = css`
  ${h6Regular};
  color: ${variables.colorWhite};
  background-color: ${variables.colorSecondaryGray};
  border-radius: ${variables.roundness3};
  padding: ${pxToRem(2)} ${variables.spaceTiny};
  vertical-align: middle;
  margin-left: ${variables.spaceTiny};
`;

type Input = {
  title?: string;
  subTitle?: string;
  headerLabel?: string;
  onRequestClose: () => void;
  style?: SerializedStyles;
};

const ModalHeader = ({
  title,
  subTitle,
  headerLabel,
  onRequestClose,
  style,
}: Input): ReactElement => {
  let shorterSubTitle;

  if (subTitle) {
    shorterSubTitle = limitWordsByCharacterLength(decode(subTitle), 48);
  }

  return (
    <div css={wrapper}>
      <div css={titleSection}>
        <h1 css={[modalTitle, style]}>
          {title}
          {headerLabel && (
            <>
              {" "}
              <span css={headerLabelStyle}>{headerLabel}</span>
            </>
          )}
        </h1>
        {shorterSubTitle && <p css={modalSubTitle}>{shorterSubTitle}</p>}
      </div>
      <CloseButton onClick={onRequestClose} />
    </div>
  );
};

export default ModalHeader;
