import React, { ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import pxToRem from "../../styles/px-to-rem";
import variables from "../../styles/variables";

const wrapper = css`
  border-top: ${pxToRem(1)} solid ${variables.colorSeparator};
  padding: ${pxToRem(14)} ${variables.spaceMedium};
`;

type Input = {
  children: ReactNode;
  style?: SerializedStyles;
};

const ModalFooter = ({ children, style }: Input): ReactElement => {
  return <div css={[wrapper, style]}>{children}</div>;
};

export default ModalFooter;
